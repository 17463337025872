<template>
  <div class="inner-pages hd-white">
    <div id="wrapper">
      <b-navbar toggleable="lg" type="light" variant="light">
        <div style="width: 100%" id="header-container">
          <div id="header" style="padding-bottom: 21px !important">
            <div class="container container-header">
              <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
              <div class="left-side">
                <div
                  class="mylogo"
                  style="margin-top: 14px; width: 113px"
                  id="logo"
                >
                  <router-link tag="a" to="/" style="background: none"
                    ><img
                      class="res-marff"
                      style="width: 100%; cursor: pointer"
                      src="../assets/images/newlogo.webp"
                      alt=""
                  /></router-link>
                </div>
                <nav id="navigation" class="style-1">
                  <ul id="responsive">
                    <li v-if="!check">
                      <router-link
                        to="/login?sell"
                        v-if="!check"
                        style="color: #50596e"
                        >Sell</router-link
                      >
                    </li>
                    <li v-if="check">
                      <router-link to="/add-property" style="color: #50596e"
                        >List new property</router-link
                      >
                    </li>
                    <li v-if="showListing">
                      <router-link
                        to="/listing?sort=price%7Cdesc"
                        style="color: #50596e"
                        >Listings</router-link
                      >
                    </li>
                    <li v-if="check">
                      <router-link to="/favourites" style="color: #50596e"
                        >Favourites</router-link
                      >
                    </li>
                    <li v-if="check">
                      <router-link to="/my-buying" style="color: #50596e"
                        >My Buying</router-link
                      >
                    </li>
                    <li v-if="check">
                      <router-link to="/my-listing" style="color: #50596e"
                        >My Selling</router-link
                      >
                    </li>
                    <li>
                      <router-link tag="a" to="/blog" style="color: #50596e"
                        >Blog</router-link
                      >
                    </li>
                    <li>
                      <router-link
                        tag="a"
                        to="/become-an-affiliate"
                        style="color: #50596e"
                        >Become a Brand Ambassador</router-link
                      >
                    </li>
                    <li class="d-none d-xl-none d-block d-lg-block">
                      <a href="login.html">Login</a>
                    </li>
                    <li class="d-none d-xl-none d-block d-lg-block">
                      <a href="register.html">Register</a>
                    </li>
                    <li
                      v-if="check"
                      class="
                        d-none d-xl-none d-block d-lg-block
                        mt-5
                        pb-4
                        ml-5
                        border-bottom-0
                      "
                    >
                      <!-- <router-link
                        to="/add-property"
                        class="button border btn-lg btn-block text-center"
                        >Add Listing<i class="fas fa-laptop-house ml-2"></i
                      ></router-link> -->
                    </li>
                  </ul>
                </nav>
              </div>

              <div
                v-show="check"
                class="header-user-menu user-menu add rightmenu"
              >
                <div class="header-user-name res-pas">
                  <span
                    class="sabc left-141 res-dnone"
                    style="top: 1px; display: none"
                  >
                    <a class="btn-floating btn-blue btn-lg btn-default"
                      ><i style="font-size: 40px" class="fa fa-gift"></i></a
                    ><span
                      style="left: 42px; top: -14px"
                      class="counter counter-lg"
                      >{{ buyerSeller.referralPoints || 0 }}</span
                    >
                  </span>
                  <span>
                    <img
                      class="gifts"
                      :src="
                        profilePic
                          ? profilePic
                          : require(`../assets/images/default-profile.webp`)
                      "
                      alt=""
                    />
                  </span>
                  <p class="res-dnone">Hi, {{ toCap(authUser.user_name) }}!</p>
                </div>
                <ul class="header-profile">
                  <li>
                    <router-link
                      style="background-color: white; color: #50596e !important"
                      to="/profile"
                    >
                      Edit profile</router-link
                    >
                  </li>
                  <li><a @click="logout()" href="#">Log Out</a></li>
                </ul>
              </div>
              <div v-show="!check">
                <router-link
                  class="disnone"
                  style="cursor: pointer"
                  tag="span"
                  to="/login"
                  >Sign In</router-link
                >
              </div>

              <div
                v-if="!check"
                class="right-side d-none d-none d-lg-none d-xl-flex sign ml-0"
              >
                <div class="header-widget sign-in">
                  <div class="show-reg-form modal-open style-1">
                    <router-link style="cursor: pointer" tag="span" to="/login"
                      >Sign In</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-navbar>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item v-if="check" class="mobilemenuli" href="#"
            ><router-link to="/add-property" style="color: #50596e"
              >List new property</router-link
            ></b-nav-item
          >
          <b-nav-item v-if="showListing" class="mobilemenuli" href="#"
            ><a
              href="javascript:void(0)"
              style="background-color: white; color: #50596e !important"
              @click="navigate('/listing?sort=price%7Cdesc')"
              >Listings</a
            ></b-nav-item
          >
          <b-nav-item v-if="check" class="mobilemenuli" href="#"
            ><a
              href="javascript:void(0)"
              style="background-color: white; color: #50596e !important"
              @click="navigate('/favourites')"
              >Favourites</a
            ></b-nav-item
          >
          <b-nav-item v-if="check" class="mobilemenuli" href="#"
            ><a
              href="javascript:void(0)"
              style="background-color: white; color: #50596e !important"
              @click="navigate('/my-listing')"
              >My Selling</a
            ></b-nav-item
          >
          <b-nav-item v-if="check" class="mobilemenuli" href="#"
            ><a
              href="javascript:void(0)"
              style="background-color: white; color: #50596e !important"
              @click="navigate('/my-buying')"
              >My Buying</a
            ></b-nav-item
          >
          <b-nav-item v-if="check" class="mobilemenuli" href="#"
            ><router-link
              style="background-color: white; color: #50596e !important"
              to="/blog"
              >Blog</router-link
            ></b-nav-item
          >
          <b-nav-item v-if="check" class="mobilemenuli" href="#"
            ><router-link
              style="background-color: white; color: #50596e !important"
              to="/become-an-affiliate"
              >Become A Brand Ambassador</router-link
            ></b-nav-item
          >
          <b-nav-item>
            <router-link
              to="/login?sell"
              v-if="!check"
              class="mobilemenuli"
              style="color: #50596e; padding-top: 2px; padding-bottom: 2px"
              >Sell</router-link
            ></b-nav-item
          >
          <b-nav-item>
            <router-link
              to="/blog"
              v-if="!check"
              class="mobilemenuli"
              style="color: #50596e; padding-top: 2px; padding-bottom: 2px"
              >Blog</router-link
            >
          </b-nav-item>

          <b-nav-item>
            <router-link
              to="/become-an-affiliate"
              v-if="!check"
              class="mobilemenuli"
              style="color: #50596e; padding-top: 2px; padding-bottom: 2px"
              >Become A Brand Ambassador</router-link
            >
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </div>

    <div class="clearfix"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { capitalize } from "../utils";
import _ from "lodash";
import $ from "jquery";

export default {
  data() {
    return {
      showListing: false,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth",
      check: "check",
      properties: "getFavourites",
      buyerSeller: "getBuyerSeller",
      blockedUsers: "getBlockedUsers",
      blockedByUsers: "getBlockedByUsers",
      getShowListingConfig: "getShowListingConfig",
    }),
    profilePic() {
      try {
        let pic = JSON.parse(this.buyerSeller.profilePic);
        return _.defaultTo(
          _.get(pic, "[0]", false),
          `../assets/images/default-profile.webp`
        );
      } catch (err) {
        if (
          typeof this.buyerSeller.profilePic == "object" &&
          this.buyerSeller.profilePic instanceof Array
        ) {
          return _.defaultTo(
            _.get(this.buyerSeller.profilePic, "[0]", false),
            require(`../assets/images/default-profile.webp`)
          );
        } else
          return _.get(
            this.buyerSeller,
            "profilePic",
            require(`../assets/images/default-profile.webp`)
          );
      }
    },
  },
  methods: {
    navigate(path) {
      this.$router.push(path);
    },
    toCap(str) {
      if (str) return capitalize(str).split(" ")[0];
      else return "";
    },
    async logout() {
      this.$store.commit("setShowLoader", true);
      await this.$store.dispatch("logout").then((res) => {
        this.$store.commit("setShowLoader", false);
        if (res == true) this.$store.commit("setDefaultsAuth");
        this.$store.commit("setDefaultsBlog");
        this.$store.commit("setDefaultsConfigurations");
        this.$store.commit("setDefaultsCoupon");
        this.$store.commit("setDefaultsMessage");
        this.$store.commit("setDefaultsMiscellaneous");
        this.$store.commit("setDefaultsProoperty");
        this.$store.commit("setDefaultsReferral");
        this.$store.commit("setDefaultsReport");

        this.$router.push("/login");
      });
    },
  },
  watch: {
    authUser(val) {
      if (Object.keys(val).length) {
        this.$store
          .dispatch("getBuyerSeller", val.buyer_seller_id)
          .then((res) => {
            if (res.success) {
            } else alert("error in fetching buyer_seller");
          });
      }
    },
  },
  async mounted() {
    if (!this.getShowListingConfig)
      this.$store
        .dispatch("getConfigurationsByKey", "show_listing")
        .then((res) => {
          if (res.data.success) {
            this.showListing = eval(_.get(res, "data.data.val.value", false));
          }
        });
    this.$emit("headerLoaded", true);
    if (_.isEmpty(this.authUser)) {
      await this.$store.dispatch("getUser");
    }
    if (
      _.isEmpty(this.buyerSeller) &&
      !_.isEmpty(this.authUser) &&
      this.check
    ) {
      await this.$store
        .dispatch("getBuyerSeller", this.authUser.buyer_seller_id)
        .then((res) => {
          if (res.success) {
          } else alert("error in fetching buyer_seller");
        });
    }
    if (!this.blockedUsers.length && this.check)
      await this.$store.dispatch("blockedUsers");
    if (!this.blockedByUsers.length && this.check)
      await this.$store.dispatch("blockedBy");
    if (_.isEmpty(this.properties) && this.check) {
      await this.$store.dispatch("getFavourites");
    }
  },
  created() {
    this.$nextTick(() => {
      $(".user-menu").on("click", function () {
        $(this).toggleClass("collapse");
      });
    });
  },
};
</script>

<style scoped src="../assets/css/fontawesome-all.css"></style>
<style scoped src="../assets/css/fontawesome-5-all.min.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>
<style scoped src="../assets/css/menu.css"></style>
<style scoped src="../assets/css/styles.css"></style>
<style scoped src="../assets/css/colors/black.css"></style>

<style scoped>
@media (min-width: 300px) and (max-width: 700px) {
  .res-dnone {
    display: none;
  }
  header-profile {
    margin-left: -37px !important;
  }
  .res-marff {
    margin: 0px !important;
  }
  .res-pas {
    padding-top: 36px !important;
  }
}

.header-profile {
  margin-left: -37px;
  margin-top: 14px;
}

a.router-link-active {
  color: white !important;
  background-color: #548f4d;
  border-radius: 5px;
}
.mobilemenuli {
  color: black;
  font-size: 21px;
  padding-left: 30px;
}
span.sabc:after {
  content: none;
}

@media (min-width: 320px) and (max-width: 480px) {
  .mylogo {
    top: 12px !important;
  }
  .rightmenu {
    top: -4px;
  }
}

@media (min-width: 700px) and (max-width: 40000px) {
  .disnone {
    display: none;
  }
}
</style>
